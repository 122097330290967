var path_to_lang,   // path to the language file, based on the current language
    map,            // Goggle Map object
    markers = [],   // stores all the GMap marker objects
    iws = [];       // stores all iw content


function initMap() {


    // draw Google Map with HR county in the center
    map = new google.maps.Map(document.getElementById('map'), {
        center: {lat: 46.531652, lng: 25.614215},
        zoom: 9
    });


    if ($.fn.dataTable.isDataTable('.table-smart')) {
        // table has been initialized already
        table = $('.table-smart').DataTable();
    } else {
        // table is initializing now
        $('.table-smart').DataTable( {
            'order': [[ 0, 'asc' ]],
            'responsive': true,
            'language': {
                'url' : path_to_lang,
            },
            'pagingType': 'numbers',
            'initComplete': function(settings, json) {
                // DataTables has finished its initialisation.
                drawMembers();
            },
        });
    }
}



function drawMembers() {

    // the bellow part will be executed only in a certain page, based on 'page_title' variable's content
    // 'page_title' variable is declared in base.html template as global JS variable

    if ((page_title.toUpperCase() == 'DESPRE ASOCIAŢIE') || (page_title.toUpperCase() == 'RÓLUNK')) {

        // table should have been initialized already
        if ($.fn.dataTable.isDataTable('.table-smart')) {
            table = $('.table-smart').DataTable();
            // hide the last column
            table.column( 4 ).visible( false );
        } else {
            console.log('No data table found!');
        }

        // contains the latLng-s to be drawed on the map
        var latLngs = table.column(4).data();
        // contains the locality names
        var iw1 = table.column(1).data();
        var iw2 = table.column(2).data();
        var iw3 = table.column(3).data();


        for (var i = 0; i < latLngs.length; i++) {
            var comma = latLngs[i].search(',');
            var lat = Number(latLngs[i].slice(0,comma));
            var lng = Number(latLngs[i].slice(comma+1, 19));

            var title1 = table.column(2).header();
            var title2 = table.column(3).header();

            var iw_content = '<p><strong>' + iw1[i] + '</strong></p>' +
                             '<p>' + $(title1).html() + ': ' + iw2[i] + '</p>' +
                             '<p>' + $(title2).html() + ': ' + iw3[i] + '</p>';

            addMarker(lat, lng, iw_content);
        }

        // Add a marker clusterer to manage the markers.
        // Old path: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
        var markerCluster = new MarkerClusterer(map, markers,
            { imagePath: '/static/images/m'}
        );
    }
}


function addMarker(lat, lng, iw_content) {

    // global variable:  only one info window should be open on the map at any one time
    infoWindow = new google.maps.InfoWindow();

    var marker = new google.maps.Marker({
        position: { lat, lng },
        map: map,
        icon: gmap_icon,
        info: iw_content // new propriety, witch contains the locality name displayed in the infoWindow
    });

    // Event on marker: user clicks on the marker, open corresponding infoWindow
    marker.addListener('click', function(event) {
        infoWindow.setContent( this.info );
        infoWindow.open( map, this );
    });

    markers.push(marker);

}


$(document).ready(function() {

    // select the proper language
    // https://datatables.net/manual/i18n
    switch (current_language) {
    case 'ro':
        path_to_lang = '//cdn.datatables.net/plug-ins/1.10.16/i18n/Romanian.json';
        break;
    case 'hu':
        path_to_lang = '//cdn.datatables.net/plug-ins/1.10.16/i18n/Hungarian.json';
        break;
    default:
        path_to_lang = '//cdn.datatables.net/plug-ins/1.10.16/i18n/English.json';
        break;
    }

    // Trick:
    //    Initially Google Map is in a closed accordion item on the page;
    //    When the end user opens the accordion item, call google.maps.event.trigger(map, 'resize'); in order to show the map properly formatted;
    //
    //    *** Note ***: add 'members' CSS class in django CMS 'Accordion Item' plugin's advanced settings
    //
    $('.members > .collapse').on('shown.bs.collapse', function () {
        $('.table-smart').DataTable().columns.adjust().draw();
        var center = map.getCenter();
        google.maps.event.trigger(map, 'resize');
        map.setCenter(center);
    });

});
